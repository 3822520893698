import React from "react";
import styled from "styled-components";

import { Container, Row, Col } from "react-bootstrap";
import { device } from "../../utils";

import {
  Title,
  // Button,
  Section,
  Box,
  Text,
  Badge,
} from "../../components/Core";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";

const ImgRight = styled(Box)`
  padding-left: 100px;
`;

const BoxPrice = styled(Box)`
  border-radius: 10px;
  background-color: rgb(255, 255, 255, 0.1);
  padding-left: 25px;
  padding-right: 20px;
  padding-top: 13px;
  padding-bottom: 10px;
  @media ${device.md} {
    min-width: 400px;
    max-width: 400px;
  }
`;

const Hero = (props) => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section bg={props.color} className="position-relative">
        <div className="pt-5"></div>
        <Container>
          <Row className="align-items-center position-relative">
            <Col lg="7">
              <div
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <Box pt={[4, null, null, 0]}>
                  <Title color="light">{props.title}</Title>
                  <Text color="lightShade">{props.description}</Text>

                  <BoxPrice mt={4} mb={3}>
                    <Text color="lightShade">{props.product}</Text>
                    <Box className="d-flex align-items-center">
                      <Title color="light" mb="0!important" mr={3}>
                        {props.price}
                      </Title>
                      <Box style={{ marginLeft: "auto" }}>
                        <Badge bg="success" style={{ fontSize: 18 }}>
                          {props.price_label}
                        </Badge>
                      </Box>
                    </Box>
                    <Text color="lightShade" variant="small">
                      {props.original_price}
                    </Text>
                  </BoxPrice>

                  {/* <div className="d-flex pt-2">
                    <ProductQty mr={3}>
                      <FormControl
                        type="number"
                        className="text-center"
                        value={qty}
                        onChange={() => {}}
                      />
                      <BtnGroup>
                        <button onClick={handleIncQty}>+</button>
                        <button onClick={handleDecQty}>-</button>
                      </BtnGroup>
                    </ProductQty>
                    <Button bg="warning" color="dark" mb={2}>
                      Add to Cart
                    </Button>
                  </div> */}
                </Box>
              </div>
            </Col>
            <Col
              lg="4"
              className="position-static d-none d-lg-block"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="500"
              data-aos-once="true"
            >
              <ImgRight className="img-fluid">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: props.featuredimage,
                  }}
                  // style={{ height: 200 }}
                />
              </ImgRight>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Hero;
