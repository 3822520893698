import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Box, Text } from "../../components/Core";

const StyledBox = styled(Box)`
  background-color: rgb(0, 0, 0, 0.05);
`;

const FeatureBox = styled(Box)`
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
`;

const FeatureCard = ({ color, iconName, title, children, ...rest }) => (
  <FeatureBox
    bg="light"
    py="25px"
    px="30px"
    height="90%"
    borderRadius={10}
    mb={4}
    {...rest}
  >
    <StyledBox
      size={69}
      borderRadius="50%"
      color={color}
      fontSize="28px"
      className="d-flex justify-content-center align-items-center"
    >
      <i className={`fas fa-${iconName}`}></i>
    </StyledBox>
    <div>
      <Text
        color="heading"
        as="h3"
        fontSize={4}
        fontWeight={500}
        letterSpacing={-0.75}
        my={3}
      >
        {title}
      </Text>
      <Text fontSize={2} lineHeight={1.75}>
        {children}
      </Text>
    </div>
  </FeatureBox>
);

const Feature = (props) => {
  return (
    <>
      {/* <!-- Feature section --> */}
      <Section
        bg={props.color}
        className="position-relative"
        // pb="40px !important"
        pt="0px !important"
        pb="0px !important"
        // mt="-80px"
      >
        <Container>
          <Row className="align-items-center">
            <Col lg="12" style={{ marginTop: -40 }}>
              <Row>
                {props.usps.map((usp, i) => (
                  <Col md="4" key={i}>
                    <FeatureCard
                      color={props.color}
                      iconName={usp.icon}
                      title={usp.title}
                    >
                      {usp.subtitle}
                    </FeatureCard>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Feature;
