import React from "react";
import { graphql } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/offer/Hero";
import CTA from "../sections/offer/CTA";
import Feature from "../sections/offer/Feature";
import CTA2 from "../sections/offer/CTA 2";

export const PromoPageTemplate = ({ data }) => {
  return (
    <>
      <Hero
        title={data.title}
        description={data.description}
        featuredimage={data.featuredimage}
        color={data.color}
        product={data.product}
        price={data.price}
        original_price={data.original_price}
        price_label={data.price_label}
      />
      <CTA
        color={data.color}
        cta_title={data.cta_title}
        cta_subtitle={data.cta_subtitle}
        cta_button={data.cta_button}
        cta_url={data.cta_url}
      />
      <Feature color={data.color} usps={data.usps} />
      <CTA2
        color={data.color}
        footer_title={data.footer_title}
        footer_description={data.footer_description}
        footer_button={data.footer_button}
        footer_url={data.footer_url}
      />
    </>
  );
};

const PromoPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <PageWrapper footerDark headerDark>
      <PromoPageTemplate data={post.frontmatter} />
    </PageWrapper>
  );
};

export default PromoPage;

export const promoPageQuery = graphql`
  query PromoPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        color
        product
        price
        original_price
        price_label
        cta_title
        cta_subtitle
        cta_button
        cta_url
        usps {
          title
          subtitle
          icon
        }
        footer_title
        footer_description
        footer_button
        footer_url
      }
    }
  }
`;
