import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Text } from "../../components/Core";

const SectionStyled = styled(Section)`
  /* border-bottom: 1px solid #eae9f2; */
`;

const ButtonStyled = styled(Button)`
  background-color: ${(props) => props.color};
  border-color: ${(props) => props.color};
  color: white;
`;

const CTA = (props) => (
  <>
    <SectionStyled
      bg="#F6F6F8"
      className="position-relative"
      pb="160px !important"
    >
      <Container>
        <Row>
          <Col lg="8" xl="7">
            <div className="py-4">
              <Title>{props.cta_title}</Title>
              <Text>{props.cta_subtitle}</Text>
            </div>
          </Col>
          <Col
            lg="4"
            xl="5"
            className="d-flex align-items-center justify-content-lg-end mt-4 mt-md-0"
          >
            <div>
              <a href={props.cta_url} target="_blank" rel="noopener noreferrer">
                <ButtonStyled color={props.color}>
                  {props.cta_button}
                </ButtonStyled>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default CTA;
