import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Button, Title, Section, Box, Text } from "../../components/Core";

const SectionStyled = styled(Section)`
  border-bottom: 1px solid #eae9f2;
`;

const ButtonContainer = styled(Box)`
  margin-top: 35px;
  display: flex;
  justify-content: center;
`;

const ButtonStyled = styled(Button)`
  background-color: ${(props) => props.color};
  border-color: ${(props) => props.color};
  color: white;
  white-space: normal;
`;

const CTA2 = (props) => (
  <>
    <SectionStyled className="position-relative" bg="#F6F6F8">
      <Container>
        <Row className="justify-content-center">
          <Col md="8" lg="7" xl="6">
            <div className="text-center">
              <Title>{props.footer_title}</Title>

              <Text>{props.footer_description}</Text>
              <ButtonContainer>
                <a href={props.footer_url}>
                  <ButtonStyled color={props.color}>
                    {props.footer_button}
                  </ButtonStyled>
                </a>
              </ButtonContainer>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default CTA2;
